import styled from "@emotion/styled";

export const Button = styled.button`
  ${p => p.type !== 'reset' && `
    background-image: linear-gradient(
      315deg,
      ${p.colors.col1} 0%,
      ${p.colors.col2} 50%
    );
  `};

  ${p => p.type === 'reset' && `background-color: #ecf0f1`};

  color: ${p => p.type === 'reset' ? '#636e72' : p.colors.fontColor};
  width: 100%;
  border: 1px solid ${p => p.type === 'reset' ? '#8395a7' : p.colors.dividerColor};
  border-radius: 6px;
  font-size: ${p => p.type === 'reset' ? '14px' : '18px'};
  height: ${p => p.height}px;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 2px 0;
  margin: 14px 0 0;
  cursor: pointer;
  max-width: ${p => p.maxWidth};
  transition: .3s;

  &:hover, &:focus {
    background-color: ${p => p.type === 'reset' ? '#ced6e0' : p.colors.col1};
    transition: .3s;
  }

  &:focus { 
    outline: none;
    border: 1px solid ${p => p.type === 'reset' ? '' : '#636e72'};
  }
`;