import styled from '@emotion/styled'

export const CodeSnippetContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  color: #222f3e;
  border-radius: 10px;
  padding: 10px 16px;
  margin-right: 24px;
  cursor: pointer;
`

export const Snippet = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2bec3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #747d8c;
  };


  h4 {
    text-align: center;
    letter-spacing: 2px
  };

  .keyword {
    color: #f92672
  };

  .const {
    color: #66d9ef;
    font-style: italic;
  };
  
  .string {
    color: #e6db74
  };
  
  .boolean {
    color: #9d76e3
  };
  
  .function {
    color: #8ecd2b
  };
  
  .comment {
    color: #88846f;
  }

  .argument {
    color: #fd971f;
    font-style: italic;
  }
`