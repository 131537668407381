import styled from "@emotion/styled";

export const EditorContainer = styled.div`
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
  text-align: center;
  max-height: calc(100vh - 190px);
  padding: 30px 30px 36px;
  background: #fff;
  display: flex;
  flex-direction: column
`;


export const SectionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`


export const SectionCard = styled.div`
  border: 1px solid #b2bec3;
  padding: 10px 0;
  background-color: ${p => p.selected ? '#636e72' : '#95afc080'};
  color: ${p => p.selected ? '#fff' : '#2d3436'};
  text-transform: uppercase;  
  border-radius: 6px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
  width: 30%;
  cursor: pointer;
  transition: .15s;

  ${p => !p.selected && `
    &:hover {
      background-color: #b2bec3;
      transition: .2s ease-in
    }
  `}

  ${p => p.selected && 'letter-spacing: 2px; transition .2s'}
`

export const AddButtonContainer = styled.div`
    display: inline-block;
    width: 90px;
`

export const AddButton = styled.button`
  font-family: Poppins;
  font-size: 22px;
  letter-spacing: 2px;
  padding: 0 2px;
  width: 100%;
  font-weight: 600;
  background-color: transparent;
  color: ${p => p.colors.col1};
  cursor: pointer;
  height: 34px;
  border: 1px solid ${p => p.colors.col2};
  border-radius: 4px;
  transition: .1s;
  margin-top: 20px;
  box-shadow: 0 0 2px #ccc;

  &:focus {
    outline: none;
    border: 2px solid ${p => p.colors.col1};
  }

  &:hover {
    border: 2px solid ${p => p.colors.col1};
    transition: .1s
  }
`

export const HorizontalLine = styled.hr`
  margin: ${p => p.customMargin || '35px 0 15px'};
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    #fff,
    #636e72CC,
    #fff
  );
`


// Header section -----------------------------------
export const HeaderSection = styled.div`
  margin: 6px auto 0;
  width: 100%;  

  ${p => p.checked && (
    `.MuiSwitch-colorPrimary {
      color: ${p.colors.col1} !important
    };

    .MuiSwitch-track {
      background-color: ${p.colors.col1} !important
    }`
  )}

  .MuiTypography-root {
    font-family: Poppins;
    font-weight: 600;
    color: #2d3436
  }
`

export const HeaderContent = styled.div`
    width: 90%;
    margin: 0 auto;
`

export const HeaderTextContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 14px
`

export const HeaderItem = styled.div`
    width: 30%;
`

export const HeaderImageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 14px;
  height: 240px;
`


// BG image section ---------------------------------
export const BackgroundImgSection = styled.div`
  margin: 6px auto 0;
  width: 100%;  

  ${p => p.checked && (
    `.MuiSwitch-colorPrimary {
      color: ${p.colors.col1} !important
    };

    .MuiSwitch-track {
      background-color: ${p.colors.col1} !important
    }`
  )}

  .MuiTypography-root {
    font-family: Poppins;
    font-weight: 600;
    color: #2d3436
  }
`

export const ImageTogglerWrapper = styled.div``;

export const ImageInputWrapper = styled.div`
  margin-top: 16px;
  display flex;
  justify-content: center;
`


// Menu items section -------------------------------
export const MenuItemsSection = styled.div`
  max-height: 50vh;
`


// Empty sidebar message
export const EmptyListContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center
`

export const EmptyImage = styled.img`
  width: 100px;
  margin-right: 40px;
`

export const EmptyMessage = styled.h4`

`