import React, { useContext } from "react";
import * as s from './Button.styles'

// Context
import SidebarContext from 'Context';

const Button = ({ label, height = 34, maxWidth, type = 'submit', onClick = null }) => {  
  const context = useContext(SidebarContext);

  const handleClick = () => {
    onClick && onClick();
  }

  return (
    <s.Button
      label={label}
      type={type}
      maxWidth={maxWidth}
      height={height}
      colors={context.buttonColors}
      onClick={handleClick}
    >
      {label}
    </s.Button>
  );
}

export default Button;
