import React, { useState, useEffect, useContext } from "react";
import * as s from './Editor.styles';
import { motion, AnimatePresence } from "framer-motion";

// Components
import Button from 'shared/Button/Button';
import LabelledInput from 'shared/LabelledInput/LabelledInput';
import PageHeader from 'shared/PageHeader/PageHeader';
import {default as Snackbar} from 'shared/Snackbar/Snackbar';
import ButtonsWrapper from './components/ButtonsWrapper/ButtonsWrapper';
import MenuItemsContainer from './components/MenuItemsContainer/MenuItemsContainer';
import { Select, MenuItem, Switch, FormControlLabel  } from '@material-ui/core';


// Context
import SidebarContext from 'Context';


const Editor = () => {
  const sections = ['header', 'background image', 'menu items'];
  const fonts = [
    'Architects Daughter',
    'Bad Script',
    'Bungee',
    'BioRhyme',
    'Concert One',
    'Dancing Script',
    'Major Mono Display',
    'Monoton',
    'Nanum Myeongjo',
    'Poppins',
    'Special Elite',
    'Syne Mono',
    'Yatra One',
    'Yellowtail',
    'ZCOOL KuaiLe'
  ];

  // Context
  const context = useContext(SidebarContext);


  // Component states
  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [showSuccess, setShowSuccess] = useState(false);


  // Content states
  const [companyName, setCompanyName] = useState({
    full: '',
    short: '',
    font: '',
    useImageAsHeader: false,
    headerImage: {
      urlExpanded: '',
      urlCollapsed: '',
      heightExpanded: '30pt',
      heightCollapsed: '22pt',
      align: 'center'
    }
  });
  
  const [bgImage, setBgImage] = useState({
    url: '',
    showImage: true
  });

  const [hiddenBgImage, setHiddenBgImage] = useState('')

  const [menuItems, setMenuItems] = useState([]);


  // Effects
  useEffect(() => {
    setCompanyName(context.companyName)
  }, [context.companyName])

  useEffect(() => {
    if(context.backgroundImage.url) setHiddenBgImage(context.backgroundImage.url)
    setBgImage(context.backgroundImage)
  }, [context.backgroundImage, hiddenBgImage]);

  useEffect(() => {
    const updatedArray = context.menuItems.map(item => {
      if(item.subMenuItems.length > 0) item.checked = true;
      else item.checked = false;

      return item
    });

    setMenuItems(updatedArray)
  }, [context.menuItems])


  // Handlers 
  // Header --------------------
  const handleChangeHeader = e => {
    const name = e.target.name,
      value = e.target.value;
    const companyNameCopy = { ...companyName };

    if(['urlExpanded', 'urlCollapsed', 'heightExpanded', 'heightCollapsed', 'align'].includes(name)) companyName['headerImage'][name] = value;
    else companyNameCopy[name] = value;

    setCompanyName(companyNameCopy)
  }
  
  const handleSubmitHeader = (e, reset) => {
    if (reset) {
      context.onSubmit({
        full: companyName.useImageAsHeader ? companyName.full : '',
        short: companyName.useImageAsHeader ? companyName.short : '',
        font: companyName.useImageAsHeader ? companyName.font : '',
        useImageAsHeader: companyName.useImageAsHeader,
        headerImage: {
          urlExpanded: '',
          urlCollapsed: '',
          heightExpanded: '30pt',
          heightCollapsed: '22pt',
          align: 'center'
        }
      }, 'companyName')
    }
    else {
      e.preventDefault();

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000)

      context.onSubmit(companyName, 'companyName')
    }
  }

  // Background image ------------------
  const handleChangeBGImage = e => {
    const bgImageCopy = { ...bgImage };
    bgImageCopy[e.target.name] = e.target.value;

    setBgImage(bgImageCopy)
  }

  const handleSubmitBgImage = (e, reset) => {
    if (reset) {
      context.onSubmit({
        url: '',
        showImage: bgImage.showImage
      }, 'backgroundImage')
    }
    else {
      e.preventDefault();

      if (!bgImage.showImage) {
        return context.onSubmit({url: '', showImage: false}, 'backgroundImage')
      }

      else {
        if (!bgImage.url) return;
        else {
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);              
          }, 3000);
    
          return context.onSubmit(bgImage, 'backgroundImage')
        }
      }
    }
  }

  // Toggle background image / header image
  const handleToggler = type => {
    // Header image
    if(type === 'useImageAsHeader') {
      const companyNameCopy = { 
        ...companyName,
        useImageAsHeader: !companyName.useImageAsHeader
      }
      context.onSubmit(companyNameCopy, 'companyName')
    }
    // Background image
    else {
      const image = bgImage.url || hiddenBgImage;
      context.onSubmit({
        url: !bgImage.showImage ? image : '',
        showImage: !bgImage.showImage
      }, 'backgroundImage')
    }
  }

  // Menu items
  const handleDelete = index => {
    const menuItemsCopy = [...menuItems];
    menuItemsCopy.splice(index, 1);
    
    if (menuItemsCopy.length === 0) handleSubmitMenu(null, true) // reset sidebar and show empty message

    setMenuItems(menuItemsCopy)
  };

  
  const handleArrowClick = (index, direction) => {    
    const menuItemsCopy = [...menuItems];
    const selectedElement = menuItems[index];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    
    menuItemsCopy.splice(index, 1);
    menuItemsCopy.splice(newIndex, 0, selectedElement);

    setMenuItems(menuItemsCopy)
  }

  const handleBlurMenu = values => {
    const menuItemsCopy = menuItems.map((item, index) => {      
    const url = values[index].replace(/\s+/g, '-').toLowerCase(); // replace empty spaces with dashes

      return { 
        ...item,
        name: values[index],
        to: `/${url}`
      };
    })
    
    setMenuItems(menuItemsCopy)
  }

  const handleIconSelect = (icon, index) => {
    const menuItemsCopy = [...menuItems];
    menuItemsCopy[index].icon = icon;
    setMenuItems(menuItemsCopy)
  }

  const handleSubmenuSelect = values => {
    const subMenuKeys = Object.keys(values).map(key => parseInt(key));
    const menuItemsCopy = menuItems.map((item, index) => {
      if (subMenuKeys.indexOf(index) > -1) {
        const itemCopy = {...item};
        itemCopy.subMenuItems = values[index];
        return itemCopy
      }
      return item
    });

    setMenuItems(menuItemsCopy);
  }


  const handleSubmitMenu = (e, reset) => {
    if (reset) {
      context.onSubmit([], 'menuItems')
    }
    else {
      e.preventDefault();

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);              
      }, 3000);

      // Clear out submenus for items that have submenus but toggle is set to 'off'
      const filteredItems = menuItems.map(item => {
        if (!item.checked && item.subMenuItems.length > 0) item.subMenuItems = []
        return item;  
      })

      // Filter out items with empty name and subtitles
      const filterEmpties = filteredItems.filter(item => {
        return !!item.name || !!item.subMenuItems.length
      })

      return context.onSubmit(filterEmpties, 'menuItems')
    }
  }


  const handleAddButtonClick = e => {
    e.preventDefault();
    
    const menuItemsCopy = [...menuItems];
    menuItemsCopy.unshift({ name: '', to: '', icon: '/icons/plus.svg', subMenuItems: [] })    
    setMenuItems(menuItemsCopy);
  }


  const sectionsJSX = sections.map((section, index) => {
    const selected = section === selectedSection;
    return (
      <s.SectionCard 
        onClick={() => setSelectedSection(section)}
        selected={selected}
        key={section}
      >
        {section}
      </s.SectionCard>
    )
  });


  const fontsJSX = fonts.map(font => {
    return <MenuItem key={font} value={font}>{font}</MenuItem>
  })
  

  return (
    <AnimatePresence>
      <motion.nav 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
      <PageHeader header={'Editor'} />

        <s.EditorContainer>
          <s.SectionsContainer>{sectionsJSX}</s.SectionsContainer>
        
          <s.HorizontalLine />

          {selectedSection === 'header' && (
            <s.HeaderSection colors={context.buttonColors} checked={companyName.useImageAsHeader}>
              <form onSubmit={handleSubmitHeader}>
                <s.ImageTogglerWrapper>
                  <FormControlLabel
                    control={<Switch checked={companyName.useImageAsHeader} name="useImageAsHeader" onChange={() => handleToggler('useImageAsHeader')} color={'primary'} />}
                    label="Use image as header"
                  />
                </s.ImageTogglerWrapper>
                <s.HeaderContent>
                    {companyName.useImageAsHeader ? (
                      <s.HeaderImageContent>
                        <LabelledInput
                          isEditor={true}
                          maxWidthLabel={'280px'}
                          name={'heightExpanded'}
                          label={'Expanded sidebar image height'}
                          placeholder={'pt / px'}
                          value={companyName.headerImage.heightExpanded}
                          onChange={handleChangeHeader}
                        />
                        <LabelledInput
                          isEditor={true}
                          maxWidthLabel={'280px'}
                          name={'heightCollapsed'}
                          label={'Collapsed sidebar image height'}
                          placeholder={'pt / px'}
                          value={companyName.headerImage.heightCollapsed}
                          onChange={handleChangeHeader}
                        />
                        <LabelledInput
                          isEditor={true}
                          maxWidthLabel={'240px'}
                          name={'urlExpanded'}
                          label={'Expanded sidebar image URL'}
                          placeholder={'mysite.com/image.jpg'}
                          value={companyName.headerImage.urlExpanded}
                          onChange={handleChangeHeader}
                        />
                        <LabelledInput
                          isEditor={true}
                          maxWidthLabel={'240px'}
                          name={'urlCollapsed'}
                          label={'Collapsed sidebar image URL'}
                          placeholder={'mysite.com/image.jpg'}
                          value={companyName.headerImage.urlCollapsed}
                          onChange={handleChangeHeader}
                        />
                        <LabelledInput
                            isEditor={true}
                            maxWidthLabel={'140px'}
                            name={'align'}
                            label={'Image alignment'}
                            placeholder={'left / right / center'}
                            value={companyName.headerImage.align}
                            onChange={handleChangeHeader}
                          />
                      </s.HeaderImageContent>
                    ) : (
                        <s.HeaderTextContent>
                          {/* Full name */}
                          <s.HeaderItem>
                            <LabelledInput
                              isEditor={true}
                              name={'full'}
                              label={'Full name'}
                              placeholder={'"ACME inc"'}
                              value={companyName.full}
                              onChange={handleChangeHeader}
                            />
                          </s.HeaderItem>
                    
                          {/* Short name */}
                          <s.HeaderItem>
                            <LabelledInput
                              isEditor={true}
                              name={'short'}
                              label={'Short name'}
                              placeholder={'"YO"'}
                              maxLength={3}
                              value={companyName.short}
                              onChange={handleChangeHeader}
                            />
                          </s.HeaderItem>

                          {/* Header font */}
                          <s.HeaderItem>
                            <Select 
                              variant={'outlined'}
                              name={'font'}
                              value={companyName.font}
                              onChange={handleChangeHeader}
                              style={{ 
                                height: '39px',
                                width: '100%',
                                marginTop: '6px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                borderRadius: '6px'
                              }}
                            >
                              {fontsJSX}
                            </Select>
                          </s.HeaderItem>
                        </s.HeaderTextContent>
                    )}    
                </s.HeaderContent>
                
                <s.HorizontalLine />

                <ButtonsWrapper>
                  <Button label={'✓'} maxWidth={'150px'} />
                  <Button label={'Clear'} type={'reset'} maxWidth={'150px'} onClick={() => handleSubmitHeader(null, 'reset')} />
                </ButtonsWrapper>
              </form>
            </s.HeaderSection>
          )}

          {selectedSection === 'background image' && (
            <s.BackgroundImgSection colors={context.buttonColors} checked={bgImage.showImage}>
              <form onSubmit={handleSubmitBgImage}>
                  <s.ImageTogglerWrapper>
                    <FormControlLabel
                      control={<Switch checked={bgImage.showImage} name="showImage" onChange={() => handleToggler('bgImage')} color={'primary'} />}
                      label="Include image"
                    />
                  </s.ImageTogglerWrapper>

                  <AnimatePresence>
                    {bgImage.showImage && (
                      <motion.nav 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 15 }}
                      transition={{ duration: 0.35 }}
                      >
                        <s.ImageInputWrapper>
                          <LabelledInput
                            isEditor={true}
                            maxWidth={'80%'}
                            maxWidthLabel={'100px'}
                            name={'url'}
                            label={'Image URL'}
                            placeholder={'mysite.com/image.jpg'}
                            value={bgImage.url}
                            onChange={handleChangeBGImage}
                          />
                        </s.ImageInputWrapper>
                      </motion.nav>
                    )}
                  </AnimatePresence>

                <s.HorizontalLine />

                <ButtonsWrapper>
                  <Button label={'✓'} maxWidth={'150px'} />
                  <Button label={'Clear'} type={'reset'} maxWidth={'150px'} onClick={() => handleSubmitBgImage(null, 'reset')} />
                </ButtonsWrapper>
              </form>
            </s.BackgroundImgSection>
          )}

          {selectedSection === 'menu items' && (
            <s.MenuItemsSection>        
              <form onSubmit={handleSubmitMenu}>
                {!!menuItems.length ? (
                  <MenuItemsContainer 
                    menuItems={menuItems}
                    onDelete={handleDelete}
                    onArrowClick={handleArrowClick}
                    onBlur={handleBlurMenu}
                    onIconSelect={handleIconSelect}
                    onSubmenuSelect={handleSubmenuSelect}
                  />
                ) : (
                  <s.EmptyListContainer>
                    <s.EmptyImage src={'/images/empty-list.png'}/>
                    <s.EmptyMessage>
                      Your sidebar is as empty as my shoes while I'm sleeping.
                    </s.EmptyMessage>
                  </s.EmptyListContainer>
                )}

                <s.AddButtonContainer>
                  <s.AddButton colors={context.buttonColors} onClick={e => handleAddButtonClick(e)}>+</s.AddButton>
                </s.AddButtonContainer>
                
                <s.HorizontalLine customMargin={'20px 0 8px'}/>

                <ButtonsWrapper>
                  <Button label={'✓'} maxWidth={'150px'} />
                  <Button label={'Clear'} type={'reset'} maxWidth={'150px'} onClick={() => handleSubmitMenu(null, 'reset')} />
                </ButtonsWrapper>
              </form>
            </s.MenuItemsSection>
          )}

        </s.EditorContainer>
        <Snackbar open={showSuccess} onClose={() => setShowSuccess(false)} />
      </motion.nav> 
    </AnimatePresence>
  );
}

export default Editor;


