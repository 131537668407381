import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import * as s from './Landing.styles'

// Context
import SidebarContext from 'Context';

const Landing = () => {
  const history = useHistory();
  const context = useContext(SidebarContext)

  return (
    <AnimatePresence>
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
      >
        <s.LandingContainer>
          <h2>STYLISH</h2>
          <h1>Collapsible Dashboard Sidebar</h1>
          <h3>Start Your Development With An Elegant <br/> Super-Easy-to-Implement Sidebar</h3>

          <s.ButtonContainer 
            bgColors={context.buttonColors}
            onClick={() => history.push("/editor")}
            >
              EDITOR
          </s.ButtonContainer>
        </s.LandingContainer>
      </motion.nav>
    </AnimatePresence>
  );
}

export default Landing;