import React from "react";
import './LabelledInput.scss'

const LabelledInput = ({
  error = null,
  disabled = false,
  maxWidth = null,
  maxWidthLabel = null,
  label,
  name,  
  placeholder = '',
  maxLength,
  touched = false,
  isEditor = false,
  value,
  onBlur,
  onChange,
  onKeyDown
}) => {
    return (
      <>
        <div className="form-group" style={{ margin: error ? '5px 0 0' : '5px 0', maxWidth }}>
          <label htmlFor={name} style={{ lineHeight: isEditor ? '22px' : '12px', fontSize: isEditor ? '16px' : '14px', maxWidth: maxWidthLabel }}>{label}</label>
          <input
            disabled={disabled}
            className="form-field"
            style={{ fontSize: isEditor ? '16px' : '14px' }}
            id={name}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            type="text"
            value={value}
          />
        </div>
        
        {touched && error && <span className='error-message'>{error}</span> }
      </>
    )
}

export default LabelledInput