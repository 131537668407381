import React, { useEffect, useState, useContext } from "react";
import * as s from './SubMenuItemsContainer.styles';

// Context
import SidebarContext from 'Context';


const SubMenuItemsContainer = ({ values, onSubmit }) => {
  // Context
  const context = useContext(SidebarContext);


  // State
  const [subMenuValues, setSubMenuValues] = useState([]);
  const [prevValues, setPrevValues] = useState([]);
  const [deletedItem, setDeletedItem] = useState(null);


  // Effects
  useEffect(() => {
    if (!values.length) {
      const emptyArr = [
        {
          name: '',
          to: ''
        }
      ];

      setSubMenuValues(emptyArr);
      setPrevValues(emptyArr)
    }
    else {
      const deepCopyValues = JSON.parse(JSON.stringify(values)),
        deepCopyPrevValues = JSON.parse(JSON.stringify(values));
      setSubMenuValues(deepCopyValues);
      setPrevValues(deepCopyPrevValues)
    }
  }, [values])

  
  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleSubmit(true);
    }
  }

  const handleChange = (e, index) => {
    const valuesCopy = [...subMenuValues];
    valuesCopy[index].name = e.target.value;
    
    const url = e.target.value.replace(/\s+/g, '-').toLowerCase(); // replace empty spaces with dashes
    valuesCopy[index].to = `/${url}`;
    setSubMenuValues(valuesCopy);
  }

  const handleSubmit = isSave => {
    if (isSave) {
      const filteredValues = subMenuValues.filter(item => !!item.name);
      onSubmit(filteredValues, true);
    }
    else {
      const prevValuesCopy = prevValues;
      setSubMenuValues(prevValuesCopy);
      onSubmit(null, false);
    }
  }

  const handleAddClick = () => {    
    const valuesCopy = [...subMenuValues];
    valuesCopy.unshift({ name: '', to: '' })
    setSubMenuValues(valuesCopy)
  }

  const handleDeleteClick = (e, index) => {
    e.preventDefault();

    setDeletedItem(index);

    setTimeout(() => {
      const valuesCopy = [...subMenuValues];
      valuesCopy.splice(index, 1);
      setSubMenuValues(valuesCopy);

      setDeletedItem(null);
    }, 1000)
  }


  const subMenuInputsJSX = subMenuValues.map((item, index) => (
      <s.SubmenuInputWrapper key={index} deleted={index === deletedItem} disablePointers={!!deletedItem || deletedItem === 0}>
        <s.SubmenuInput
          name={`Input-${index}`}
          type='text'
          value={item.name}
          placeholder={'Submenu name'}
          onChange={e => handleChange(e, index)}
          onKeyUp={e => handleKeyUp(e)}
          autoComplete='off'
        />
        <s.SubmenuClearButton disabled={subMenuValues.length === 1} onClick={e => handleDeleteClick(e, index)}>x</s.SubmenuClearButton>
      </s.SubmenuInputWrapper>
  ));

  const isSaveBtnDisabled = () => {
    if (subMenuValues.every(i => !i.name)) return true
    else return false
  }


  return (
    <s.SubmenusModalContainer>
      <s.SubmenusInputsWrapper>
        {subMenuInputsJSX}
      </s.SubmenusInputsWrapper>
      <s.SubmenusButtonsWrapper>
        <s.AddButton colors={context.buttonColors} onClick={handleAddClick}>+</s.AddButton>
        <s.HorizontalLine />
        <s.ActionButtonsWrapper>
          <s.ActionButton disabled={isSaveBtnDisabled()} onClick={() => handleSubmit(true)} colors={context.buttonColors}>Save</s.ActionButton>
          <s.ActionButton onClick={() => handleSubmit(false)}>Cancel</s.ActionButton>
        </s.ActionButtonsWrapper>
      </s.SubmenusButtonsWrapper>
    </s.SubmenusModalContainer>
  )
}

export default SubMenuItemsContainer