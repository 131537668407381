import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import * as s from "./Sidebar.styles";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"

// Context
import SidebarContext from 'Context';

const Sidebar = props => {
  // Context
  const context = useContext(SidebarContext);
  const { companyName } = context;
  
  const {
    menuItems = [],
    fonts = {
      header: 'ZCOOL KuaiLe',
      menu: 'Poppins'
    },
    backgroundImage = '',
    colorPalette = {
      bgColor1: 'rgba(252, 82, 150, 0.8)',
      bgColor2: 'rgba(246, 112, 98, 0.8)',
      fontColor: 'rgba(19, 15, 64)',
      fontColorSelected: 'rgba(255, 255, 255)',
      selectedBackgroundCollapsedMode: 'dark'
    }
  } = props;

  const [selected, setSelectedMenuItem] = useState('');
  const [isSidebarOpen, setSidebarState] = useState(true);
  const [header, setHeader] = useState(companyName.full);
  const [headerImage, setHeaderImage] = useState(companyName.headerImage)
  const [useImageAsHeader, setUseImageAsHeader] = useState(companyName.useImageAsHeader)

  // Key has index of the menus with subItems / value is a boolean (true === Opened / false === Collapsed)
  const [subMenusStates, setSubmenus] = useState({});    


  // Effects

  useEffect(() => {
    const updateWindowWidth = () => {
      if(window.innerWidth < 1280) setSidebarState(false);
      else setSidebarState(true)
    }
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);
  
  
  // Add index of menu item to state (for control of Opened / Collapsed states and Selected styles)
  useEffect(() => {      
    const newSubmenus = {};

    menuItems.forEach((item, index) => {
      const hasSubmenu = !!item.subMenuItems.length;
      if(hasSubmenu) {
        newSubmenus[index] = {};
        newSubmenus[index]['isOpen'] = false;
        newSubmenus[index]['selected'] = null;
      }
    });

      // Set selected submenu if user landed on one
    const path = window.location.pathname;
    const parts = path.split('/');

    if (parts.length === 3) {
      const selectedItem = parts[1].toLowerCase();
      const selectedSubItem = parts[2].toLowerCase()
      const selectedItemIndex = menuItems.findIndex(item => item.name.toLowerCase() === selectedItem);
      const selectedSubItemIndex = menuItems[selectedItemIndex]?.subMenuItems.findIndex(subItem => subItem.name.toLowerCase() === selectedSubItem);

      if (selectedItemIndex !== -1) newSubmenus[selectedItemIndex]['isOpen'] = true;
      if (selectedItemIndex !== -1 && selectedSubItemIndex !== -1) newSubmenus[selectedItemIndex]['selected'] = selectedSubItemIndex;
    }

    const compareMenus = (obj1, obj2) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      return keys1.length === keys2.length && keys1.every((value, index) => value === keys2[index])             
    }
    
    !compareMenus(newSubmenus, subMenusStates) && setSubmenus(newSubmenus);
  }, [menuItems, subMenusStates]);
  
  
  // Set selected menu item based on URL pathname
  useLayoutEffect(() => {
    const path = window.location.pathname;
    const parts = path.split('/');

    if (path !== '/' && menuItems[0] && parts[1].charAt(0).toUpperCase() !== menuItems[0].name) {
      const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
      setSelectedMenuItem(selectedItem.toLowerCase())
    }
  }, [menuItems])
  
      
  // Set header (text)
  useEffect(() => {
    isSidebarOpen ? setTimeout(() => setHeader(companyName.full), 200) : setHeader(companyName.short);
  }, [companyName.full, companyName.short, isSidebarOpen]);


  // Set header (image)
  useEffect(() => {
    setHeaderImage(companyName.headerImage)
  }, [companyName.headerImage])


  // Set useImageAsHeader
  useEffect(() => {
    setUseImageAsHeader(companyName.useImageAsHeader)
  }, [companyName.useImageAsHeader])


  const handleMenuItemClick = (name, index) => {
    setSelectedMenuItem(name);

    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

    // Toggle Opened / Collapse states if item has subitems
    if(subMenusStates.hasOwnProperty(index)){
      subMenusCopy[index]['isOpen'] = !subMenusCopy[index]['isOpen'];
      if(!subMenusCopy[index]['isOpen']) subMenusCopy[index]['selected'] = null;
      setSubmenus(subMenusCopy)
    }
    // Set all dropdown open states to False
    else {
      for(let item in subMenusStates) {
        subMenusCopy[item]['isOpen'] = false;
        subMenusCopy[item]['selected'] = null;
        setSubmenus(subMenusCopy)
      }
    }
  }

  const handleSubmenuItemClick = (menuItemIdx, subMenuItemIdx) => {      
    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));
    subMenusCopy[menuItemIdx]['selected'] = subMenuItemIdx;
    setSubmenus(subMenusCopy)
  }


  const menuItemsJSX = menuItems.map((item, index) => {    
    const hasSubmenu = subMenusStates.hasOwnProperty(index); // refer previous to previous useEffect
    
    const isOpen = subMenusStates[index]?.isOpen;
    const isMenuItemSelected = selected === item.name.toLowerCase();

    const submenuItemsJSX = item.subMenuItems.map((submenuItem, subIndex) => {        
    const isSubMenuItemSelected = subMenusStates[index]?.selected === subIndex;

      return (
        <Link
          to={`${item.to}${submenuItem.to}`}
          style={{ textDecoration: "none" }}
          key={`${submenuItem.name}-${subIndex}`}
        >
          <s.SubMenuItem 
            selected={isSubMenuItemSelected}
            colorPalette={colorPalette} 
            onClick={() => handleSubmenuItemClick(index, subIndex)}>
              {submenuItem.name}
          </s.SubMenuItem>
        </Link>
      );
    });

    return (
      <s.MenuItemContainer key={`${item.name}-${index}`}>
        <Link
          to={item.to}
          style={{ textDecoration: "none" }}
        >
          <s.MenuItem
            isSidebarOpen={isSidebarOpen}
            selected={isMenuItemSelected}
            onClick={() => handleMenuItemClick(item.name.toLowerCase(), index)}
            font={fonts.menu}
            colorPalette={colorPalette}
            hasSubmenu={hasSubmenu}
            isOpen={isOpen}
            >
              <s.Icon src={item.icon} isSidebarOpen={isSidebarOpen} />
              <s.Text isSidebarOpen={isSidebarOpen}>{item.name}</s.Text>
              {hasSubmenu && isSidebarOpen && (
                <s.DropdownIcon 
                  colorPalette={colorPalette}
                  isOpen={isOpen}
                  selected={isMenuItemSelected} 
                />
              )}
          </s.MenuItem>
        </Link>
      
      
        <AnimatePresence>
          {hasSubmenu && isOpen && (
            <motion.nav 
              initial={{ opacity: 0, y: -15 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: -30 }}
              transition={{ duration: 0.35 }}
            >
              <s.SubMenuItemContainer 
                colorPalette={colorPalette}
                isSidebarOpen={isSidebarOpen}
              >
                {submenuItemsJSX}
              </s.SubMenuItemContainer>
            </motion.nav>
          )}          
        </AnimatePresence>
      </s.MenuItemContainer>
    );
  });

  return (
    <s.SidebarContainer isSidebarOpen={isSidebarOpen} backgroundImage={backgroundImage} colorPalette={colorPalette}>
      <Link to={'/'} style={{ textDecoration: "none" }}>
        {useImageAsHeader ? (
          <s.HeaderImageContainer 
            height={isSidebarOpen ? headerImage.heightExpanded : headerImage.heightCollapsed}
            align={isSidebarOpen ? headerImage.align : 'center'}
            isSidebarOpen={isSidebarOpen}
          >
            <s.HeaderImage src={isSidebarOpen ? headerImage.urlExpanded : headerImage.urlCollapsed} />
          </s.HeaderImageContainer>
        ) : (
          <s.SidebarHeader headerFont={companyName.font} colorPalette={colorPalette}>{header}</s.SidebarHeader>
        )}
      </Link>
      <s.SidebarMenuContainer>{menuItemsJSX}</s.SidebarMenuContainer>
      <s.ToggleViewContainer onClick={() => setSidebarState(!isSidebarOpen)}>
        <s.ToggleView />
      </s.ToggleViewContainer>
    </s.SidebarContainer>
  );
}

export default Sidebar;