import styled from "@emotion/styled";

export const App = styled.div`
  display: flex;
  position: relative
`;

export const MobileMsg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 45%;
  left: 50%;
  width: 80%;
  background: #ecf0f1;
  transform: translate(-50%, -50%);
`

export const MobMsg = styled.p`
  text-align: center;
  border: 1px solid #8e44ad66;
  padding: 20px 10px;
  border-radius: 6px;
  font-size: 28px;
`

export const MobImg = styled.img`
  width: 90px;
  margin-bottom: 30px;
`

export const PalettesAndSnippetWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 3%;  
  bottom: 40px;
`