import React from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Landing from "./components/MainView/LandingPage/Landing";
import Editor from "./components/MainView/Editor/Editor";
import LoremPage from "./components/MainView/LoremPage/LoremPage";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/editor" component={Editor} />
      <Route path="/:lorem" component={LoremPage} />
    </Switch>
  );
}
