import styled from '@emotion/styled';

export const EditorButtonContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 3%;
`

export const EditorButton = styled.button`
  border: 1px solid ${p => p.borderColor};
  padding: 0 16px; 
  border-radius: 3px;  
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  letter-spacing: 2px;
  height: 46px;
  width: 130px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  font-family: Poppins;
  font-color: #333;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .1s ease-in;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid ${p => p.borderColorHover};
    transition: .1s ease-in;
  }
`

export const EditorIcon = styled.img`
  height: 14px;
  width: 14px;
`