import styled from "@emotion/styled";

export const MainViewContainer = styled.div`
  height: 100vh;
  width: 80%;
  position: relative;
  margin: 0 auto;
  padding: 0 40px;

  h1 {
    font-size: 2rem;
  }
`;
