import React from "react";
import styled from "@emotion/styled";

const ButtonWrapper = ({ children }) => {  
  return (
    <ButtonWrapperStyled>
      {children}
    </ButtonWrapperStyled>
  );
}

export default ButtonWrapper;


const ButtonWrapperStyled = styled.div`
  width: 30%;
  padding: 0 40px;
  min-width: 340px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between
`