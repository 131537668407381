import React, { useState, useLayoutEffect, useEffect } from 'react';
import * as s from './App.styles';
import * as def from './defaultValues';
import Colors from './colors';
import { Helmet } from 'react-helmet'


// Components
import Sidebar from './components/Sidebar/Sidebar';
import MainView from './components/MainView/MainView';
import ColorPalettes from './components/ColorPalettes/ColorPalettes';
import CodeSnippet from './components/CodeSnippet/CodeSnippet';
import MyLinks from './components/MyLinks/MyLinks';


// Context
import SidebarContext from './Context';


const App = () => {
  const [codeSnippetOpened, setCodeSnippetOpened] = useState(false)
  const [isMobile, setIsMobile] = useState(false);
  const [currentPalette, setPalette] = useState({});
  const [companyName, setCompanyName] = useState({
    full: '',
    short: '',
    font: '',
    useImageAsHeader: false,
    headerImage: {
      urlExpanded: '',
      urlCollapsed: '',
      heightExpanded: '',
      heightCollpased: '',
      align: 'center'
    }
  });
  const [backgroundImage, setBgImage] = useState({
    url: '',
    showImage: true
  });
  const [menuItems, setMenuItems] = useState([])


  // Set defaults
  useLayoutEffect(() => {
    setPalette(Colors['dejaVu']);
    setCompanyName(def.companyName);
    setBgImage(def.backgroundImage);
    setMenuItems(def.menuItems);

    if(window.innerWidth < 640) setIsMobile(true);
    else setIsMobile(false)
  }, []);


  useEffect(() => {
    const updateWindowWidth = () => {
      if(window.innerWidth < 640) setIsMobile(true);
      else setIsMobile(false)
    }
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  
  const handlePaletteClick = paletteName => {
    switch(paletteName) {
      case 'swampyGreen': 
        return setPalette(Colors['swampyGreen']);
      case 'pinkAndBlue': 
        return setPalette(Colors['pinkAndBlue']);
      case 'julyBlue': 
        return setPalette(Colors['julyBlue']);
      case 'gothicDark': 
        return setPalette(Colors['gothicDark']);
      case 'ashes': 
        return setPalette(Colors['ashes']);
      case 'beaverBrown': 
        return setPalette(Colors['beaverBrown']);
      case 'oceanBlue': 
        return setPalette(Colors['oceanBlue']);
      case 'saltNPepper': 
        return setPalette(Colors['saltNPepper']);
      case 'dejaVu':
      default:
       return setPalette(Colors['dejaVu']);
    }
  }


  const handleCustomPaletteClick = (values, reset) => {
    if (reset) return handlePaletteClick('dejaVu');
    return setPalette(values)
  }


  const handleValuesUpdate = (values, type) => {
    if (type === 'companyName') {
      return setCompanyName(values)
    }

    if (type === 'backgroundImage') {
      return setBgImage(values)
    }

    if (type === 'menuItems') {
      return setMenuItems(values)
    }
  }

  const fonts = {
    header: 'ZCOOL KuaiLe',
    menu: 'Poppins'
  }

  const context = {
    buttonColors: {
      col1: currentPalette.bgColor1, 
      col2: currentPalette.bgColor2,
      fontColor: currentPalette.fontColorSelected,
      dividerColor: currentPalette.dividerColor
    },
    palette: currentPalette,
    companyName,
    backgroundImage,
    menuItems,
    onSubmit: handleValuesUpdate
  }

  const seo = {
    title: "Stylish React Sidebar",
    description:
      "Build a Stylish Collapsible Dashboard Sidebar with your React project. Start your development with an elegant super-easy-to-implement sidebar.",
    url: "https://sidebar.studio/",
    image: "https://eugeville.files.wordpress.com/2015/03/logo.png"
  };

  return (
    <>
      <Helmet
        title={seo.title}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description
          },
          { property: "og:title", content: seo.title },
          { property: "og:url", content: seo.url },
          { property: "og:image", content: seo.image },
          { property: "og:image:type", content: "image/png" },
          { property: "twitter:image:src", content: seo.image },
          { property: "twitter:title", content: seo.title },
          { property: "twitter:description", content: seo.description }
        ]}
      />
      <s.App>
        { isMobile ? (
          <s.MobileMsg>
            <s.MobImg src="/images/empty-list.png" alt="empty-list-raccon" />
            <s.MobMsg>Sorry,<br/><b>Stylish Sidebar</b> editor is not available on small screens.</s.MobMsg>
          </s.MobileMsg>
        ) : (
          <SidebarContext.Provider value={context}>
            <Sidebar
              menuItems={menuItems}
              fonts={fonts}
              backgroundImage={backgroundImage.url}
              colorPalette={currentPalette}
            />

            <MainView buttonColor={{col1: currentPalette.bgColor1, col2: currentPalette.bgColor2}}/>
            <MyLinks />

            <s.PalettesAndSnippetWrapper>
              <CodeSnippet 
                onClick={() => setCodeSnippetOpened(!codeSnippetOpened)}
                open={codeSnippetOpened}
              />
              <ColorPalettes 
                onClick={handlePaletteClick}
                onFormSubmit={handleCustomPaletteClick}
              />
            </s.PalettesAndSnippetWrapper>
          </SidebarContext.Provider>
        )}      
      </s.App>
    </>
  );
}

export default App;