import React from "react";
import * as s from "./MainView.styles";

import Routes from "../../Routes";
import EditorButton from 'components/MainView/EditorButton/EditorButton'

const MainView = () => {
  return (
    <s.MainViewContainer>
        <Routes />
        <EditorButton />
      </s.MainViewContainer>
  )
}

export default MainView