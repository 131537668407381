import styled from '@emotion/styled';

export const LandingContainer = styled.div`
    margin-top: 12%;
    width: 100%;
    text-align: center;

    h2 {        
        font-size: 180%;
        letter-spacing: 10px;
    }

    h1 {
        font-size: 250%;
        letter-spacing: 1px;
    }

    h3 {
        margin-top: 30px
    }
`;

export const ButtonContainer = styled.div`
    border: 1px solid #bdc3c7;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 3px;
    color: ${p => p.bgColors.fontColor};
    width: 120px;
    margin: 40px auto 0;
    padding: 12px;
    cursor: pointer;
    background-image: linear-gradient(
        315deg,
        ${p => p.bgColors.col1} 0%,
        ${p => p.bgColors.col2} 74%
    );
    transition: .3s;
    
      &:hover {
        background-color: ${p => p.bgColors.col1};
        transition: .3s;
      }
`