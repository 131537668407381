import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import * as s from './EditorButton.styles'
import { useLocation } from 'react-router-dom'; 

// Context
import SidebarContext from 'Context';

const EditorButton = () => {
  const context = useContext(SidebarContext);
  const history = useHistory();
  const location = useLocation();

  const [showEditorButton, setShowEditorButton] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    
    if (path === '/' || path === '/editor') {
      setShowEditorButton(false)
    }
    else setShowEditorButton(true)
  }, [location]);

  return (
    <s.EditorButtonContainer>
      <AnimatePresence>
        {showEditorButton && (
          <motion.nav
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: -25 }}
            transition={{ duration: 0.35 }}
          >
            <s.EditorButton
              borderColor={context.buttonColors.col1}
              borderColorHover={context.buttonColors.col2}
              onClick={() => history.push("/editor")}
            >
              <s.EditorIcon src={'/icons/pencil.svg'} />
              Editor
            </s.EditorButton>
          </motion.nav>
        )}
      </AnimatePresence>
    </s.EditorButtonContainer>
  );
}

export default EditorButton;