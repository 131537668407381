import React, { useContext } from 'react';
import * as s from './CodeSnippet.styles'
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Context
import SidebarContext from 'Context';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#2d3436',
    color: '#fff',
    border: '2px solid #333',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '700px',
    textAlign: 'left'
  },
}));


const CodeSnippet = ({ open = false, onClick }) => {
  // Modal styles  
  const classes = useStyles();

  // Context
  const context = useContext(SidebarContext);
  const { palette, companyName, backgroundImage, menuItems } = context;
  const { headerImage, useImageAsHeader } = companyName;

  const headerJSX = () => {
    return useImageAsHeader ? (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> headerImage <span className='keyword'>=</span> &#123; <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;urlExpanded: <span className='string'>'{headerImage.urlExpanded}'</span>,<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;urlCollapsed: <span className='string'>'{headerImage.urlCollapsed}'</span>,<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;heightCollapsed: <span className='string'>'{headerImage.heightCollapsed}'</span>,<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;heightExpanded: <span className='string'>'{headerImage.heightExpanded}'</span>,<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;align: <span className='string'>'{headerImage.align}'</span><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/><br/>
      </>
    ) : (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> header <span className='keyword'>=</span> &#123; <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fullName: <span className='string'>'{companyName.full}'</span>,<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;shortName: <span className='string'>'{companyName.short}'</span><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/><br/>
      </>
    )
  }

  const menuItemsJSX = menuItems.map((item, index) => {
    const hasSubs = item.subMenuItems.length;
    const subs = item.subMenuItems.map((subItem, index) => {
      return <div key={`Sub-${subItem.name}-${subItem-index}`}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;&nbsp;name:&nbsp;<span className='string'>'{subItem.name}',</span>
        &nbsp;to: <span className='string'>'{subItem.to}'</span>&nbsp;&#125;
        {index !== item.subMenuItems.length - 1 && <>,</>}
        <br/>
      </div>
    })

    return hasSubs ? (
      <div key={`${item.name}-${index}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;&nbsp;name: <span className='string'>'{item.name}'</span>,
          to: <span className='string'>'{item.to}'</span>,
          icon: <span className='string'>'{item.icon}'</span>,
          subMenuItems: [<br/>
            {subs}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,<br/>
      </div>
    ) : (
      <div key={`${item.name}-${index}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;&nbsp;name: <span className='string'>'{item.name}'</span>,
          to: <span className='string'>'{item.to}'</span>,
          icon: <span className='string'>'{item.icon}'</span>,
          subMenuItems: []&nbsp;&#125;{index !== menuItems.length - 1 && <>,</>}<br/>
      </div>
    )
  })

  return (
    <>
      <s.CodeSnippetContainer onClick={onClick}>
        &#x3c; &#x3e;
      </s.CodeSnippetContainer>

      <Modal
        aria-labelledby="code-snippet-modal"
        aria-describedby="code-snippet-modal-content"
        className={classes.modal}
        open={open}
        onClose={onClick}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <s.Snippet>
            <div className={classes.paper}>
              <h4 id="transition-modal-icons">Your component:</h4>
              <pre>
                <code className='language-jsx'>
                  <span className='keyword'>import&nbsp;</span>React,&nbsp;&#123;&nbsp;useState&nbsp;&#125;&nbsp;<span className='keyword'>from&nbsp;</span><span className='string'>'react'</span>&#59; <br/>
                  <span className='keyword'>import&nbsp;</span>&#123;&nbsp;useHistory&nbsp;&#125;&nbsp;<span className='keyword'>from&nbsp;</span><span className='string'>'react-router-dom'</span>&#59; <br/>
                  <span className='keyword'>import&nbsp;</span>StylishSidebar&nbsp;<span className='keyword'>from&nbsp;</span><span className='string'>'stylish-sidebar'</span>&#59; <br/><br/><br/>
                  
                  
                  <span className='const'>const</span> <span className='function'>YourComponent</span> <span className='keyword'>=</span> () <span className='const'>=&#x3e; </span>&#123;<br/>
                  {/* State */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> [isOpen, <span className='function'>setIsOpen</span>] <span className='keyword'>=</span> <span className='function'>useState</span>(<span className='boolean'>true</span>)&#59; <br/><br/>
                  

                  {/* Color palette  */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> colorPalette <span className='keyword'>=</span> &#123; <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bgColor1: <span className='string'>'{palette.bgColor1}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bgColor2: <span className='string'>'{palette.bgColor2}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fontColor: <span className='string'>'{palette.fontColor}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fontColorSelected: <span className='string'>'{palette.fontColorSelected}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;dividerColor: <span className='string'>'{palette.dividerColor}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selectedBackgroundCollapsedMode: <span className='string'>'{palette.selectedBackgroundCollapsedMode}'</span><br/>                    
                  &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/><br/>

                  {/* Fonts */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> fonts <span className='keyword'>=</span> &#123; <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;header: <span className='string'>'{companyName.font}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;menu: <span className='string'>'Poppins'</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/><br/>

                  {/* Header */}
                  { headerJSX() }

                  {/* Background image */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> backgroundImage <span className='keyword'>=</span> &#123; <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;url: <span className='string'>'{backgroundImage.url}'</span>,<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;showImage: <span className='boolean'>{backgroundImage.showImage ? <>true</> : <>false</> }</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/><br/>

                  {/* Menu items */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> menuItems <span className='keyword'>=</span> [ <br/>
                    {menuItemsJSX}
                  &nbsp;&nbsp;&nbsp;&nbsp;]<br/><br/>

                  {/* Use history */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> history <span className='keyword'>=</span> <span className='function'>useHistory</span>()&#59;<br/><br/>
                  
                  {/* Click handlers */}
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> handleMenuItemClick <span className='keyword'>=</span> <span className='argument'>index&nbsp;</span><span className='const'>=&#x3e; </span>&#123;<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;history.<span className='function'>push</span>(menuItems[<span className='argument'>index</span>].to)<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&#125;&#59;<br/><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='const'>const</span> handleSubMenuItemClick <span className='keyword'>=</span> (<span className='argument'>menuItemIndex, subMenuItemIndex</span>)&nbsp;<span className='const'>=&#x3e; </span>&#123;<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;history.<span className='function'>push</span>(<span className='string'>&#96;</span><span className='keyword'>$&#123;</span>menuItems[<span className='argument'>menuItemIndex</span>].to<span className='keyword'>&#125;</span><span className='keyword'>$&#123;</span>menuItems[<span className='argument'>menuItemIndex</span>].subMenuItems[<span className='argument'>subMenuItemIndex</span>].to<span className='keyword'>&#125;</span><span className='string'>&#96;</span>)<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&#125;&#59;

                  <br/><br/><br/>

                  &nbsp;&nbsp;&nbsp;&nbsp;<span className='keyword'>return&nbsp;</span>(<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x3c;<span className='const'>AppContainer</span>&#x3e;&nbsp;<span className='comment'>&#x2f;&#x2f; make sure this parent includes 'display: flex' property</span> <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x3c;<span className='const'>StylishSidebar</span><br/>
                  
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>backgroundImage</span><span className='keyword'>=&#123;</span>backgroundImage<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>colorPalette</span><span className='keyword'>=&#123;</span>colorPalette<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>useImageAsHeader</span><span className='keyword'>=&#123;</span><span className='boolean'>{useImageAsHeader ? <>true</> : <>false</> }</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>header{useImageAsHeader && <>Image</>}</span><span className='keyword'>=&#123;</span>header{useImageAsHeader && <>Image</>}<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>fonts</span><span className='keyword'>=&#123;</span>fonts<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>menuItems</span><span className='keyword'>=&#123;</span>menuItems<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>widthExpanded</span><span className='keyword'>=&#123;</span><span className='string'>'20%'</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>widthCollapsed</span><span className='keyword'>=&#123;</span><span className='string'>'5%'</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>minWidth</span><span className='keyword'>=&#123;</span><span className='string'>'80px'</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>maxWidth</span><span className='keyword'>=&#123;</span><span className='string'>'280px'</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>isOpen</span><span className='keyword'>=&#123;</span>isOpen<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>showToggler</span><span className='keyword'>=&#123;</span><span className='boolean'>true</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>onTogglerClick</span><span className='keyword'>=&#123;</span>()&nbsp;<span className='const'>=&#x3e; </span><span className='function'>setIsOpen</span>(<span className='keyword'>!</span>isOpen)<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>onHeaderClick</span><span className='keyword'>=&#123;</span>()&nbsp;<span className='const'>=&#x3e; </span>history.<span className='function'>push</span>(<span className='string'>'editor'</span>)<span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>onMenuItemClick</span><span className='keyword'>=&#123;</span><span className='function'>handleMenuItemClick</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='function'>onSubMenuItemClick</span><span className='keyword'>=&#123;</span><span className='function'>handleSubMenuItemClick</span><span className='keyword'>&#125;</span><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&#x3e;<br/><br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x3c;<span className='const'>MainView&nbsp;</span>/&#x3e;<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x3c;/<span className='const'>AppContainer</span>&#x3e;<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                  &#125;<br/><br/><br/>
                  <span className='keyword'>export default&nbsp;</span><span className='function'>YourComponent</span>
                </code>
              </pre>            
            </div>
          </s.Snippet>
        </Fade>
      </Modal>
    </>
  )
}

export default CodeSnippet