import styled from '@emotion/styled'

export const ColorPalettesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: ${p => p.customSelected ? '440px' : '400px'};
    height: 30px;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    border-radius: 10px;
    background-color: #ecf0f1;
    transition: .1s ease-in-out width;
`;

export const Circle = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    background: conic-gradient(
        ${p => p.bgColor1} 180deg,
        ${p => p.bgColor2} 180deg 360deg);
    border-radius: 50%;
    cursor: pointer
`

export const CustomOption = styled.p`
    font-weight: 600;
    font-size: 14px;
    color: #222f3e;
    cursor: pointer
`

export const CustomPopup = styled.div`
    position: absolute;
    right: 0;
    bottom: 46px;
    height: auto;
    width: 300px;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    border-radius: 10px;
    background-color: #ecf0f1;
    padding: 10px 24px 18px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
`