import styled from "@emotion/styled";

export const SubmenusModalContainer = styled.div`
  margin: 14px 14px 0;
  width: 580px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SubmenusInputsWrapper = styled.div`
  width: 50%;
  max-height: 50vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #747d8c;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb:hover {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }  
  
  &::-webkit-scrollbar-corner  {
    background-color: transparent;
  }
`

export const SubmenuInputWrapper = styled.div`  
  ${p => p.deleted && 'opacity: 0.1; transition: .2s'};  
  ${p => p.disablePointers && 'pointer-events: none'};

  display: flex;
  align-items: center
`

export const SubmenuInput = styled.input`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;  
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  padding: 8px 14px;
  margin: 8px 0;
`;

export const SubmenuClearButton = styled.button`
  font-family: Poppins;
  font-size: 20px;
  padding: 0 16px 2px;
  font-weight: 600;
  background-color: transparent;
  color: #dfe6e9;
  cursor: pointer;
  height: 43px;
  border: 1px solid #636e72;
  border-radius: 2px;
  transition: .2s;
  border-left-style: none;

  &:focus {
    outline: none;
    border: 1px solid #fff;
    border-left-style: none;
  }

  ${p => !p.disabled && `
    &:hover {
      background-color: #394245;
      transition: .2s
    }
  `};

  &:disabled {
    opacity: 0.8;
    color: grey;
    cursor: default
  }
`;

export const SubmenusButtonsWrapper = styled.div`
  padding: 2px;
  width: 40%;
`

export const AddButton = styled.button`
  border-radius: 50px;
  padding: 9px 16px;
  font-size: 24px;
  border: 1px solid ${p => p.colors.col1};
  color: #fff;
  background-color: #353b48;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: #2f3640;
    transition: .3s;
  }

  &:focus { 
    outline: none;
    padding: 7px 14px;
    border: 3px solid ${p => p.colors.col1};
    transition: .3s;
  }
`

export const HorizontalLine = styled.hr`
  margin: 30px 0;
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #636e72CC, #fff, #636e72CC);
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

export const ActionButton = styled.button`
  ${p => p.colors && `background-image: linear-gradient(
    315deg,
    ${p.colors.col1} 0%,
    ${p.colors.col2} 50%
  )`};

  ${p => p.disabled && 'opacity: 0.4; border: 1px solid grey !important; pointer-events: none;'};

  border: 1px solid ${p => p.colors ? p.colors.dividerColor : '#8395a7'};
  letter-spacing: 2px;
  border-radius: 6px;
  height: 34px;
  font-family: poppins;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  color: ${p => p.colors ? p.colors.fontColor : '#fff'};
  text-transform: uppercase;
  width: 100px;

  &:hover, &:focus {
    transition: .3s;
    border: 1px solid ${p => p.colors ? '' : '#fff'};
  }

  &:focus { 
    outline: none;
    border: 1px solid ${p => p.colors ? '' : '#fff'};
  }
`