import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import styled from "@emotion/styled";

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'pink',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomSnackbar = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <SnackbarWrapper>
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
          <Alert onClose={onClose} severity="success">
            Great Success!
          </Alert>
        </Snackbar>
      </div>
    </SnackbarWrapper>
  );
}

export default CustomSnackbar

const SnackbarWrapper = styled.div`
  // .MuiSnackbar-anchorOriginBottomCenter {
  //   background: #27ae6033 !important
  // }
`