import React from "react";
import * as s from './PageHeader.styles'

const PageHeader = ({ header }) => {
  return (
    <s.Header>{header}</s.Header>
  );
}

export default PageHeader;
