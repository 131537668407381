import React, { useState, useEffect, useRef } from "react";
import * as s from "./ColorPalettes.styles";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from 'formik';
import LabelledInput from 'shared/LabelledInput/LabelledInput';
import Button from 'shared/Button/Button'


const ColorPalettes = ({ onClick, onFormSubmit }) => {
    const initialValues = {
        bgColor1: '',
        bgColor2: '',
        fontColor: '',
        fontColorSelected: '',
        dividerColor: '',
        selectedBackgroundCollapsedMode: ''
    }
    
    // State ------------------------------
    const [isCustomOpen, setIsCustomOpen] = useState(false);
    const [customSelected, setCustomSelected] = useState(false);
    const [customColors, setCustomColors] = useState({ col1: null, col2: null})


    // Refs -------------------------------
    const refCustomColors = useRef();

    
    // Effects ---------------------------
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);


    // Formik -----------------------------
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            handleSubmit(values);
        },
        onReset: values => {
            setCustomSelected(false);
            setCustomColors({ col1: null, col2: null });
            handleSubmit(values, true);
        },
        validate: values => {
            let errors = {};
            const validateField = (fieldName, fieldValue) => {
                if (!fieldValue && fieldName !== 'selectedBackgroundCollapsedMode') errors[fieldName] = 'Required';
                else if (!/^#[0-9A-F]{6}$/i.test(fieldValue) && fieldName !== 'selectedBackgroundCollapsedMode') errors[fieldName] = 'Invalid color format';

                if (fieldName === 'selectedBackgroundCollapsedMode' && fieldValue.length) {
                    if (fieldValue !== 'dark' && fieldValue !== 'light') errors[fieldName] = `Either 'dark' or 'light'`;
                }
            }

            validateField('bgColor1', values.bgColor1)
            validateField('bgColor2', values.bgColor2)
            validateField('selectedBackgroundCollapsedMode', values.selectedBackgroundCollapsedMode)

            return errors
        }
    });


    const handleCustomClick = () => {
        setIsCustomOpen(!isCustomOpen);
    }

    
    const handleOutsideClick = e => {
        if (e.target.innerText === 'Custom' || refCustomColors.current?.contains(e.target)) return;
        setIsCustomOpen(false);
    }


    const handleSubmit = (values, reset = false) => {
        const valuesCopy = { ...values };
        
        if (values.bgColor1.length === 7) valuesCopy.bgColor1 = `${values.bgColor1}CC`;
        if (values.bgColor2.length === 7) valuesCopy.bgColor2 = `${values.bgColor2}CC`;
        
        if (!values.fontColor) valuesCopy.fontColor = '#333';        
        if (!values.fontColorSelected) valuesCopy.fontColorSelected = '#FFF';
        if (!values.dividerColor) valuesCopy.dividerColor = values.bgColor2;
        if (!values.selectedBackgroundCollapsedMode) valuesCopy.selectedBackgroundCollapsedMode = 'dark';
        
        if (!reset) {
            setCustomSelected(true)
            setCustomColors({ col1: values.bgColor1, col2: values.bgColor2 })
        };

        onFormSubmit(valuesCopy, reset)
    }


    return (
        <>
            <s.ColorPalettesContainer customSelected={customSelected}>
                <s.Circle bgColor1={'rgba(246, 112, 98)'} bgColor2={'rgba(252, 82, 150)'} onClick={() => onClick('dejaVu')} />
                <s.Circle bgColor1={'rgba(11, 171, 100)'} bgColor2={'rgba(59, 183, 143)'} onClick={() => onClick('swampyGreen')} />
                <s.Circle bgColor1={'rgba(67, 67, 67)'} bgColor2={'rgba(0, 0, 0)'} onClick={() => onClick('gothicDark')} />
                <s.Circle bgColor1={'rgba(126, 232, 250)'} bgColor2={'rgba(238, 192, 198)'} onClick={() => onClick('pinkAndBlue')} />
                <s.Circle bgColor1={'rgba(100, 125, 238)'} bgColor2={'rgba(127, 83, 172)'} onClick={() => onClick('julyBlue')} />
                <s.Circle bgColor1={'rgba(230, 234, 240)'} bgColor2={'rgba(184, 198, 219)'} onClick={() => onClick('ashes')} />
                <s.Circle bgColor1={'rgba(149, 59, 32)'} bgColor2={'rgba(87, 15, 10)'} onClick={() => onClick('beaverBrown')} />
                <s.Circle bgColor1={'rgba(10, 188, 249)'} bgColor2={'rgba(44, 105, 209)'} onClick={() => onClick('oceanBlue')} />
                <s.Circle bgColor1={'rgba(91, 100, 103)'} bgColor2={'rgba(47, 67, 83)'} onClick={() => onClick('saltNPepper')} />
                <s.CustomOption onClick={handleCustomClick} id={'custom-option-text'}>Custom{
                    customSelected ? <span>&nbsp;:&nbsp;</span> : ''
                }</s.CustomOption>
                
                {customSelected && (
                    <s.Circle bgColor1={customColors.col1} bgColor2={customColors.col2} style={{ cursor: 'auto' }} />
                )}
            </s.ColorPalettesContainer>

            <AnimatePresence>
                {isCustomOpen && (
                <motion.nav 
                    initial={{ opacity: 0, y: 25, x: -48 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 15, x: -48 }}
                    transition={{ duration: 0.35 }}
                >
                    <s.CustomPopup ref={refCustomColors}>
                        <p style={{ textAlign: 'center', fontSize: '14px', marginBottom: '8px' }}>Need gradient ideas? &nbsp;
                            <span 
                                onClick={() => window.open('https://www.eggradients.com/', "_blank")}
                                style={{ cursor: 'pointer', color: '#0984e3', fontWeight: 600 }}
                                >
                                    Click here
                            </span>
                        </p>

                        <form onSubmit={formik.handleSubmit}>
                            <LabelledInput 
                                name={'bgColor1'}
                                label={'Background 1'}
                                placeholder={'#303030 (required)'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bgColor1}
                                error={formik.errors.bgColor1}
                                touched={formik.touched.bgColor1}
                            />
                            <LabelledInput 
                                name={'bgColor2'}
                                label={'Background 2'}
                                placeholder={'#303030 (required)'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bgColor2}
                                error={formik.errors.bgColor2}
                                touched={formik.touched.bgColor2}
                            />
                            <LabelledInput 
                                name={'fontColor'}
                                label={'Font'}
                                placeholder={'#303030 / red etc'}
                                onChange={formik.handleChange}
                                value={formik.values.fontColor}
                            />
                            <LabelledInput 
                                name={'fontColorSelected'}
                                label={'Font selected'}
                                placeholder={'#303030 / red etc'}
                                onChange={formik.handleChange}
                                value={formik.values.fontColorSelected}
                            />
                            <LabelledInput 
                                name={'dividerColor'}
                                label={'Divider'}
                                placeholder={'#303030 / red etc'}
                                onChange={formik.handleChange}
                                value={formik.values.dividerColor}
                            />
                            <LabelledInput 
                                name={'selectedBackgroundCollapsedMode'}
                                label={'Collapsed BG'}
                                placeholder={'dark / light'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.selectedBackgroundCollapsedMode}
                                error={formik.errors.selectedBackgroundCollapsedMode}
                                touched={formik.touched.selectedBackgroundCollapsedMode}
                            />

                            <Button type='submit' label={'✓'} height={30} />
                            <Button type='reset' label={'Clear'} height={30} onClick={formik.handleReset} />
                        </form>
                    </s.CustomPopup>
                </motion.nav>
                )}          
          </AnimatePresence>
        </>
    )
}

export default ColorPalettes;