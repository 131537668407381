import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PageHeader from 'shared/PageHeader/PageHeader';
import { jokes } from '../../../defaultValues';


const Contacts = () => {
  const [randomJoke, setJoke] = useState('');

  const path = window.location.pathname,
      parts = path.split('/'),
      pageName = parts[1].replace(/-/g, " "),
      pageNameCapitalized = capitalize(pageName);

  useEffect(() => {
    const rando = jokes[Math.floor(Math.random() * jokes.length)];    
    setJoke(rando)
  }, [path])

  return (
    <AnimatePresence>
      <motion.nav 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <PageHeader header={pageNameCapitalized} />

        <h3 style={{ fontWeight: 300 }}>This will be your <span style={{ fontWeight: 600 }}>{pageNameCapitalized}</span> page.</h3> 
        {
          parts.length > 2 && 
          <>
            <p>It has submenus and you are currently on <span style={{ fontWeight: 600 }}>{capitalize(parts[2].replace(/-/g, " "))}</span> section.</p>
            <br />
          </>
        }
        
        <h5><span style={{ fontWeight: 300 }}>Page url:</span> {path}</h5>
        <hr style={{ 
          margin: '35px 0 15px',
          width: '100%',
          border: 0,
          height: '1px',
          backgroundImage: 'linear-gradient(to right, #fff, #636e72CC, #fff)' }} />
        
        <p style={{ fontStyle: 'italic', float: 'right' }}>{`"${randomJoke}"`}</p>
      </motion.nav>
    </AnimatePresence>
  );
}

export default Contacts;


const capitalize = str => {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}