import React, { useEffect, useState, useContext } from "react";
import * as s from './MenuItemsContainer.styles';

// Components
import SubMenuItemsContainer from '../SubMenuItemsContainer/SubMenuItemsContainer'
import LabelledInput from 'shared/LabelledInput/LabelledInput';
import { Switch, FormControlLabel, IconButton, Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// Context
import SidebarContext from 'Context';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    letterSpacing: '2px',
    backgroundColor: '#2d3436',
    color: '#fff',
    border: '2px solid #333',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


export const icons = [
  '/icons/about.svg',
  '/icons/blog.svg',
  '/icons/camera.svg',
  '/icons/car.svg',
  '/icons/cloud.svg',
  '/icons/contacts.svg',
  '/icons/destinations.svg',
  '/icons/graph1.svg',
  '/icons/graph3.svg',
  '/icons/gym.svg',
  '/icons/helm.svg',
  '/icons/home.svg',
  '/icons/joy.svg',
  '/icons/keypad.svg',
  '/icons/leaf.svg',
  '/icons/moon.svg',
  '/icons/sun.svg',
  '/icons/piggy.svg',
  '/icons/sun.svg',
  '/icons/wine.svg'  
];


const MenuItemsContainer = ({ menuItems, onDelete, onArrowClick, onBlur, onIconSelect, onSubmenuSelect }) => {
  // Context
  const context = useContext(SidebarContext);

  // Modal styles  
  const classes = useStyles();

  // State
  const [menuValues, setMenuValues] = useState([]);  
  const [subMenuValues, setSubMenuValues] = useState({});

  const [deletedItem, setDeletedItem] = useState(null);
  const [iconsModalOpened, setIconsModalOpened] = useState(false);
  const [submenusModalOpened, setSubmenusModalOpened] = useState(false);
  const [clickedIconIndex, setClickedIconIndex] = useState(null);
  const [clickedSubmenuIndex, setClickedSubmenuIndex] = useState(null);


  // Effects
  useEffect(() => {
    const menus = [];
    const subMenus = {};

    menuItems.forEach((item, index) => {
      menus.push(item.name);
      if (item.checked) subMenus[index] = item.subMenuItems;
    })

    setMenuValues(menus);
    setSubMenuValues(subMenus);
  }, [menuItems]);


  const handleItemChange = (e, index) => {
    const menuValuesCopy = [...menuValues];
    menuValuesCopy[index] = e.target.value;
    setMenuValues(menuValuesCopy)
  }

  const handleKeyDown = e => {
    if (e.which === 13) e.preventDefault()
  }

  const handleItemDelete = index => {
    setDeletedItem(index);

    setTimeout(() => {
      onDelete(index)
      setDeletedItem(null);
    }, 1000)
  }

  const handleIconClick = index => {
    setIconsModalOpened(true);
    setClickedIconIndex(index)
  }

  const handleIconsClose = () => {
    setIconsModalOpened(false);
    setClickedIconIndex(null)
  }

  const handleIconSelectClick = icon => {
    onIconSelect(icon, clickedIconIndex);
    handleIconsClose();
  }

  const handleSubmenuEditClick = (e, index) => {
    e.preventDefault();
    setSubmenusModalOpened(true);
    setClickedSubmenuIndex(index);
  }

  const handleSubmenusModalSubmit = (values, isSave) => {
    if (isSave) {
      const subMenusCopy = {...subMenuValues};
      subMenusCopy[clickedSubmenuIndex] = values;

      
      setSubMenuValues(subMenusCopy);
      onSubmenuSelect(subMenusCopy)
    }
    else { // Cancel click
      const subMenusCopy = {};

      // Ignore empty values
      for (let i in subMenuValues) {
        if (!!subMenuValues[i].length) {
          subMenusCopy[i] = subMenuValues[i];
        }
        else menuItems[i].checked = false
      }

      setSubMenuValues(subMenusCopy); 
      onSubmenuSelect(subMenusCopy)
    }
    
    setSubmenusModalOpened(false);
    setClickedSubmenuIndex(null);
  }


  const toggleSubItems = index => {    
    const subMenuValuesCopy = {...subMenuValues};

    // If sub menus exists
    if (!!subMenuValues[index]) {
      const checked = menuItems[index].checked;
      
      // If checked
      if (checked) {
        // If values exists, simply uncheck
        if (subMenuValues[index].length) menuItems[index].checked = false;
        
        // If no values, remove object from submenus array
        else delete subMenuValuesCopy[index];
      }

      // If unchecked
      else menuItems[index].checked = !menuItems[index].checked;
    }


    // If sub menus doesn't exist
    else {
      menuItems[index].checked = true;
      subMenuValuesCopy[index] = {};
      subMenuValuesCopy[index] = [];      
      setSubmenusModalOpened(true);
      setClickedSubmenuIndex(index);
    }

    setSubMenuValues(subMenuValuesCopy);
  }


  const iconRows = icons.reduce((rows, icon, index) => {
    return (index % 5 === 0 ? rows.push([icon]) 
      : rows[rows.length-1].push(icon)) && rows;
  }, []);
  const iconRowsJSX = iconRows.map((row, rowIndex) => (
    <s.IconListRow key={`Row-${rowIndex}`}>
      { row.map((icon, iconIndex) => (
        <s.IconModalWrapper key={`Row-${rowIndex}-${iconIndex}`} onClick={() => handleIconSelectClick(icon)} hoverColor={context.buttonColors.col1}>
          <s.Icon src={icon} isModal={true} />
      </s.IconModalWrapper>
      ) )}
    </s.IconListRow>
  ));

  
  const rowsJSX = menuItems.map((item, index) => {
    const subItemsToggleChecked = item.checked || false;

    return (
      <s.MenuItemRow key={`${item.name}-${index}`} deleted={index === deletedItem} disablePointers={!!deletedItem || deletedItem === 0}>
        <s.MenuInput>
          <LabelledInput
            isEditor={true}
            name={`Row-${index + 1}-${item.name}`}
            label={'Name'}
            maxWidthLabel={'80px'}
            value={menuValues[index] || ""}
            onChange={e => handleItemChange(e, index)}
            onKeyDown={e => handleKeyDown(e)}
            onBlur={() => onBlur(menuValues)}
          />
        </s.MenuInput>

        <s.MenuIconsContainer>
          <s.IconWrapper onClick={() => handleIconClick(index)}>
            <s.Icon src={item.icon} />
          </s.IconWrapper>
        </s.MenuIconsContainer>

        <s.MenuSubmenus colors={context.buttonColors} checked={subItemsToggleChecked}>
          <s.ImageTogglerWrapper checked={subItemsToggleChecked}>
            <FormControlLabel
              control={<Switch checked={subItemsToggleChecked} name="showImage" onChange={() => toggleSubItems(index)} color={'primary'} />}
              label="Has submenus?"
            />
          </s.ImageTogglerWrapper>

          {subItemsToggleChecked && (
            <s.EditSubmenusButton 
              colors={context.buttonColors}
              onClick={(e) => handleSubmenuEditClick(e, index)}
            >
              EDIT
            </s.EditSubmenusButton>
          )}
        </s.MenuSubmenus>

        <s.MenuActionIcons>
          <s.ArrowsWrapper>
            <IconButton aria-label="down-up" disabled={index === 0} onClick={() => onArrowClick(index, 'up')}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton aria-label="down-arrow" disabled={index === menuItems.length - 1} onClick={() => onArrowClick(index, 'down')}>
              <ArrowDownwardIcon />
            </IconButton>
          </s.ArrowsWrapper>
          <s.DeleteWrapper>
            
            <IconButton aria-label="delete" onClick={() => handleItemDelete(index)}>
              <DeleteIcon />
            </IconButton>
          </s.DeleteWrapper>
        </s.MenuActionIcons>
      </s.MenuItemRow>
    )
  });

  return (
    <s.MenuItemsContainer>
        {rowsJSX}

        {/* Icons modal */}
        <Modal
          aria-labelledby="icons-modal"
          aria-describedby="icons-modal-list"
          className={classes.modal}
          open={iconsModalOpened}
          onClose={handleIconsClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={iconsModalOpened}>
            <div className={classes.paper}>
              <h4 id="transition-modal-icons">Select an icon</h4>
              <s.IconListContainer>
                { iconRowsJSX }
              </s.IconListContainer>
            </div>
          </Fade>
        </Modal>

        {/* Submenus modal */}        
        <Modal
          aria-labelledby="submenus-modal"
          aria-describedby="submenus-modal-list"
          className={classes.modal}
          open={submenusModalOpened}
          onClose={() => handleSubmenusModalSubmit(null, false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={submenusModalOpened}>
            <div className={classes.paper}>
              <h4 id="transition-modal-submenus">Add submenus</h4>
              <SubMenuItemsContainer
                values={(clickedSubmenuIndex !== null && subMenuValues[clickedSubmenuIndex]) || []}
                onSubmit={handleSubmenusModalSubmit}
              />
            </div>
          </Fade>
        </Modal>
    </s.MenuItemsContainer>
  );
}

export default MenuItemsContainer;