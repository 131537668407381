import styled from "@emotion/styled";

export const SidebarContainer = styled.div`
  position: relative;
  min-width: 80px;
  max-width: 280px;
  width: ${p => p.isSidebarOpen ? '20%' : '5%'};
  height: 100vh;
  color: ${p => p.colorPalette.fontColorSelected} ;
  background-image: linear-gradient(
      315deg,
      ${p => p.colorPalette.bgColor1} 0%,
      ${p => p.colorPalette.bgColor2} 74%
    ),
    url(${p => p.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: .2s ease-in all;
`;

export const SidebarHeader = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  height: 62px;
  letter-spacing: 6px;
  font-family: ${p => p.headerFont};
  font-size: 20px;
  color: ${p => p.colorPalette.fontColorSelected};
`;

export const HeaderImageContainer = styled.div`
  padding: ${p => p.isSidebarOpen ? '10px 20px 22px' : '10px 0'};
  text-align: ${p => p.align || 'center'};
  height: ${p => p.height || '30pt'};
`

export const HeaderImage = styled.img`
  height: inherit
`

export const SidebarMenuContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 15px;
    height: 18px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #747d8c;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb:hover {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }  
  
  &::-webkit-scrollbar-corner  {
    background-color: transparent;
  }
`;

export const MenuItemContainer = styled.div``;

export const MenuItem = styled.div`
  ${p => !p.isSidebarOpen && `
    text-align: center;
    ${p.selected && `background-color: ${p.colorPalette.selectedBackgroundCollapsedMode === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'}` }
    `
  };
  
  position: relative;
  padding: 6px 20px;
  text-decoration: none;
  color: ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor};
  font-weight: 600;
  transition: 0.2s ease-in all;
  font-family: ${p => p.font};

  
  &:hover {
     color: ${p => p.colorPalette.fontColorSelected};
     transition: .1s ease-in all;
  }

  &:nth-of-type(1) {
    white-space: nowrap;
  }

  &:after{
    content: "";
    border: 1px solid ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.dividerColor};
    ${p => !p.isSidebarOpen && p.selected && `border: 1px solid #fff`};
    margin: 8px 0 4px;
    display: ${p => p.selected && p.hasSubmenu && p.isOpen && p.isSidebarOpen ? 'none' : 'block'};
  }
  
  ${p => !p.selected && `
    &:hover {
      &:after{
        content: "";
        border: 1px solid rgba(255, 255, 255, 0.2);
        transition: .1s ease-in all;
        margin: 8px 0 4px;
        display: block
      }
  }`};
`;

export const SubMenuItemContainer = styled.div`
  ${p => !p.isSidebarOpen && 'text-align: center'};
  ${p => p.isSidebarOpen && 'padding-left: 15%'};
  font-size: 14px;
`

export const SubMenuItem = styled.p`
  color: ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor};
  ${p => p.selected && 'font-weight: bold; letter-spacing: 2px'};
  transition: font-weigh .5s, letter-spacing .4s;
  &:hover{
    color: ${p => p.colorPalette.fontColorSelected};
  }
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
  ${p => p.isSidebarOpen && 'padding-right: 20px;'};
  transition: .3s ease-in all;
`;

export const Text = styled.p`
  display: ${p => p.isSidebarOpen ? 'inline' : 'none'};
`

export const DropdownIcon = styled.span`
  position: absolute;
  right: 24px;
  top: ${p => p.isOpen ? '16px' : '12px'};  
  border: solid ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: ${p => p.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};  
  transition: all .4s .005s;
`

export const ToggleViewContainer = styled.div`
  margin: 0 auto;
  width: 30%;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;  
`

export const ToggleView = styled.div`
  position: relative;
  height: 40px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: .25em;
    width: 100%;
    height: .1em;
    background: #fff;
    box-shadow: 
      0 .75em 0 0 #fff,
      0 1.5em 0 0 #fff;
  }
`