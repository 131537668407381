import React, { useState, useContext } from "react";
import * as s from "./MyLinks.styles";
import { motion, AnimatePresence } from "framer-motion";

// Context
import SidebarContext from 'Context';


const MyLinks = () => {
  const [showName, setShowName] = useState(false);
  const context = useContext(SidebarContext);

  return (
    <s.MyLinksContainer>
      <s.NameWrapper>
        <AnimatePresence>          
            { showName &&
              <>
                <motion.nav 
                  initial={{ opacity: 0, x: 15 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, y: -15 }}
                  transition={{ duration: 0.35 }}
                >
                  <s.Text textShadowColor={context.buttonColors.col1}>designed and developed by</s.Text>                
                </motion.nav>
                <motion.nav 
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, x: -15 }}
                  transition={{ duration: 0.35 }}
                >
                  <s.Name textShadowColor={context.buttonColors.col1}>Eugene Costov</s.Name>
                </motion.nav>
              </>
            }
        </AnimatePresence>
      </s.NameWrapper>
      <s.GithubIcon 
        src={'icons/github-logo.svg'}
        onClick={() => window.open('https://github.com/EugeCos/stylish-sidebar', "_blank")}
        onMouseOver={() => setShowName(true)} 
        onMouseLeave={() => setShowName(false)}
      />
    </s.MyLinksContainer>
  )
}

export default MyLinks