import styled from "@emotion/styled";

export const MenuItemsContainer = styled.div`
  padding: 10px 16px;
  min-height: 84px;
  max-height: 30vh;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2bec3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #747d8c;
  }
`;

export const MenuItemRow = styled.div`
  ${p => p.deleted && 'opacity: 0.5'};
  ${p => p.disablePointers && 'pointer-events: none'};

  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid  #dfe6e9;
  border-radius: 2px;
  transition: .15s;
  padding: 0 10px;
  margin: 16px 0;

  &:first-of-type {
    margin: 0 0 16px
  }

  &:hover {
    background: #f5f6fa;
    transition: .15s
  }
`

export const MenuInput = styled.div`
  width: 40%;
`

export const MenuIconsContainer = styled.div`  
  width: 15%;
  display: flex;
  justify-content: center;
`

export const IconWrapper = styled.span`
  background: #535c68;
  border-radius: 100px;
  padding: 9px 10px 4px;
  cursor: pointer;
  transition: .15s ease-in background;

  &:hover {
    background: #2d3436;
    transition: .15s ease-in background
  }
`

export const Icon = styled.img`
  height: ${p => p.isModal ? '26px' : '17px'};
  width: ${p => p.isModal ? '26px' : '17px'};
`

export const MenuSubmenus = styled.div`
  width: 25%;
  position: relative; // for Edit button;
  
  ${p => p.checked && (
    `.MuiSwitch-colorPrimary {
      color: ${p.colors.col1} !important
    };

    .MuiSwitch-track {
      background-color: ${p.colors.col1} !important
    }`
  )}

  .MuiTypography-root {
    font-family: Poppins;
    font-weight: 600;
    color: #2d3436;
  }
`

export const ImageTogglerWrapper = styled.div`
  ${p => p.checked && 'width: 220px'}
`;

export const EditSubmenusButton = styled.button`
  position: absolute;
  top: 6px;
  right: -10px;
  border: 1px solid ${p => p.colors.col2};
  border-radius: 2px;
  padding: 2px 16px;
  background-color: ${p => p.colors.col1};
  font-family: Poppins;
  letter-spacing: 1px;
  font-weight: 600;
  color:  ${p => p.colors.fontColor};
  cursor: pointer;

  &:focus { 
    outline: none;
    border: 1px solid #636e72;
  }
`

export const MenuActionIcons = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-around
`

export const ArrowsWrapper = styled.div``
export const DeleteWrapper = styled.div``


export const IconListContainer = styled.div`
  margin-top: 24px 0;
  padding: 0 20px;
  width: 380px;
`

export const IconListRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0;

  &:last-child { margin: 12px 0 }
`

export const IconModalWrapper = styled.div`
  background: #535c68;
  border-radius: 100px;
  padding: 16px 17px 11px;
  cursor: pointer;
  transition: .15s ease-in background;

  &:hover {
    border: 2px solid ${p => p.hoverColor};
    padding: 14px 15px 9px;
    background: #000000;
    transition: .15s ease-in background;
  }
`