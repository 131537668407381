import styled from '@emotion/styled';

export const MyLinksContainer = styled.div`
    position: absolute;
    right: 0;
    margin: 20px;
`;

export const GithubIcon = styled.img`
  height: 36px;
  width: 36px;
  cursor: pointer;
`;

export const NameWrapper = styled.div`
  position: relative;
`

export const Text = styled.p`
  color: #535c68;
  text-shadow: 0 0 1px ${p => p.textShadowColor};
  letter-spacing: 2px;
  position: absolute;
  right: 54px;
  top: 0px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  text-align: right
`

export const Name = styled.span`
  text-shadow: 0 0 1px black;  
  position: absolute;
  letter-spacing: 1px;
  right: 54px;
  top: 20px;
  width: 158px;
  text-align: right
`